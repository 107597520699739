import axios from 'axios';
import React, {useState, useEffect} from 'react'
import {Link} from "react-router-dom";
import SlideRenderer from "./HookSlideRenderer";

function  BlogContent({ match: { params: { blogId} } })  {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        axios.get(`https://www.revontulicoding.fi/RESTAPI_Front/v2/caller.php?token=4fe66ef852c39069efdcc76d71adc5106b96b761&table=null&scope=slides&page=blog_content&language=en&id=${blogId}`)
            .then(res => {
                setData(res.data)
                setIsLoading(false)
            })
            .catch(err => {
                console.error(err);
            })
    }, [blogId])

    //when data is arrived, load the meta tags
    if(!isLoading)
    {
        //documents title
        document.title = data.content.title;
        //description
        document.getElementsByTagName("META")[3].content = data.meta_description;
        //keywords
        document.getElementsByTagName("META")[4].content = data.meta_keywords;
        //title
        document.getElementsByTagName("META")[5].content = "Revontuli coding | " + data.content.title;
    }

    return(
        <>
            {!isLoading && (
                <>

                    <SlideRenderer
                        page={"blog"}
                        slide={data.slide}
                    />

                    <div className={"container marketing"}>

                        <div className={"row"}>
                            <div className={"col mx-auto"}>
                                <div className="blockquote">
                                    <p className="mb-0">{data.content.title}</p>
                                    <footer className="blockquote-footer">{data.content.date} <cite title="Source Title">{data.content.author}</cite></footer>
                                </div>
                            </div>
                            <div className={"col mx-auto img-blog"}>
                                <Link to="/blog">Go back to previous page</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className={"col mx-auto"}>
                                <div className="app_desc blog-text" dangerouslySetInnerHTML={{__html: data.content.content}} />
                            </div>
                            <div className={"col mx-auto img-blog"}>
                                <img src={data.content.image} className={"rounded mx-auto d-block"} alt={"my-image"}/>
                            </div>
                        </div>

                        {/*<p>"https://dev.to/dsckiitdev/dynamic-pages-using-react-router-2pm"</p>*/}


                    </div>

                </>

            )}
        </>
    );
}

export default BlogContent;