import React, {useState} from 'react';
import {Route, Switch} from "react-router-dom";
import BlogContent from '../Hooks/HookBlogContent';
import AiContent from '../Hooks/HookAiContent'

const NavBar = React.lazy(() => import('../Hooks/HookNavbar'));
const HookFooter = React.lazy(() => import('../Hooks/HookFooter'));
const HookBlog = React.lazy(() => import('../Hooks/HookBlog'))
//const BlogContent = React.lazy(() => import('../Hooks/HookBlogContent'));
const HookLanding = React.lazy(() => import('../Hooks/HookLanding'));
const HookServices = React.lazy(() => import('../Hooks/HookServices'));
const HookAbout = React.lazy(()=> import('../Hooks/HookAbout'));
const HookProjects = React.lazy(() => import('../Hooks/HookProjects'));
const HookContact = React.lazy(() => import('../Hooks/HookContact'));
const HookAi = React.lazy(() => import('../Hooks/HookAi'));

const App = () => {

    //get browser language
    let browserLang = window.navigator.language.split('-')[0];

    const [language, setLanguage] = useState(browserLang);

    const handleLanguage = (language)  => {
        setLanguage(language)
    };


    return(
      <>
        <NavBar language={language} handleChangeLanguage={handleLanguage}/>

          <Switch>
              <Route
                  exact path="/"
                  render={(props) => <HookLanding language={language} />}
              />
              <Route exact path="/about"
                     render={(props) => <HookAbout language={language} />}
              />
              <Route
                  exact path="/blog"
                  render={(props) => <HookBlog language={language} />}
              />
              <Route
                  exact path="/projects"
                  render={(props) => <HookProjects language={language} />}
              />
              <Route
                  exact path="/services"
                  render={(props) => <HookServices language={language} />}
              />

              <Route path="/blog/:blogId" component={BlogContent} />

              <Route exact path="/contact"
                     render={(props) => <HookContact language={language} />}
             />
              <Route
                  exact path="/ai"
                  render={(props) => <HookAi language={language} />}
              />

              <Route path="/ai/:aiId" component={AiContent} />

          </Switch>

        <HookFooter language={language} />
      </>
    );
}

//export the global App
export default App;
