import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import 'react-mdl/extra/material.css';
import 'react-mdl/extra/material.js';
import { BrowserRouter } from 'react-router-dom';


import App from './components/App';
import './css/App.css';
import './css/Mobile.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <BrowserRouter>
    <React.Suspense fallback={<p>suspense</p>}>
            <App />
    </React.Suspense>
    </BrowserRouter>,
    document.getElementById('App'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
