import axios from 'axios';
import React, {useState, useEffect} from 'react'

const SlideRenderer = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(props.slide);
    

    /*
    useEffect(() => {
        axios.get(`https://www.revontulicoding.fi/RESTAPI_Front/v1/caller.php?token=4fe66ef852c39069efdcc76d71adc5106b96b761&table=null&scope=slides&page=${props.page}`)
            .then((response) => {
                setData(response.data[0]);
                setIsLoading(false)
                console.log(response.data)
            })
    }, [props.page])
    */
    return(
        <>
            {!isLoading && (
                <section className="app_container">
                    <div className="titleContainer text-uppercase text-center">
                        <img className="titleImg" src={data.path} alt={data.description}/>
                        {/*<div className="titleCentered text-uppercase text-center">{data.title}</div>*/}
                    </div>
                </section>
            )}
        </>
    );

}

export default SlideRenderer