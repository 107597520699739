import axios from 'axios';
import React, {useState, useEffect} from 'react'
import {Link} from "react-router-dom";

import SlideRenderer from "./HookSlideRenderer";

function  AiContent({ match: { params: { aiId} } })  {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        axios.get(`https://www.revontulicoding.fi/RESTAPI_Front/v2/caller.php?token=4fe66ef852c39069efdcc76d71adc5106b96b761&table=null&scope=slides&page=ai_content&language=en&id=${aiId}`)
            .then(res => {
                setData(res.data)
                setIsLoading(false)
            })
            .catch(err => {
                console.error(err);
            })
    }, [aiId])


    if(!isLoading)
    {
        //documents title
        document.title = data.content.title;
        //description
        document.getElementsByTagName("META")[3].content = data.meta_description;
        //keywords
        document.getElementsByTagName("META")[4].content = data.meta_keywords;
        //title
        document.getElementsByTagName("META")[5].content = "Revontuli coding | " + data.content.title;
    }

    return(
        <>
            {!isLoading && (
                <>
                    <SlideRenderer
                        slide={data.slide}
                    />

                    <div className={"container marketing"}>
                        <div className={"row"}>
                            <div className={"col mx-auto"}>
                                <div className="blockquote">
                                    <p className="mb-0">{data.content.title}</p>
                                    <footer className="blockquote-footer">{data.content.date} <cite title="Source Title">{data.content.author}</cite></footer>
                                </div>
                            </div>
                            <div className={"col mx-auto img-blog"}>
                                <Link to="/ai">Go back to previous page</Link>
                            </div>
                        </div>

                        <div className={"row"}>
                            <iframe title={data.content.title}
                                    className={"iframe_content"}
                                    src={data.content.link}
                                    allowFullScreen={true}
                                    width={"100%"}
                                    height={"1000px"}

                            />
                        </div>

                    </div>
                </>
            )}
        </>
    );

}

export default AiContent